.popup {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 2rem;
  text-align: center;
  background-color: var(--brand-color-main);
  color: var(--white);
  box-shadow: 0 0 8px 2px var(--gray-shadow);

  @media screen and (min-width: 45em) {
    bottom: 2rem;
    left: 2rem;
    max-width: 20rem;
    border-radius: 5px;
  }
}

.popupText {
  margin-top: 0;
}

.acceptButton {
  appearance: none;
  border: none;
  padding: 0.8rem 1rem;
  border-radius: 5px;
  background-color: var(--black);
  color: var(--white);
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: var(--black-lighter);
  }

  &:focus {
    outline: 2px dashed var(--brand-color-lighter);
  }
}
